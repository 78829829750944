import { Temporal } from "@js-temporal/polyfill";

const compressArrayOfObj = arr => arr.map(obj => Object.values(obj));

export const processGameForSave = (data) => {
  const compressedParcels = data.parcels.map(({ id, crop, stats, landPrice }) => [
    id,
    crop ? Object.values(crop) : null,
    stats ? compressArrayOfObj(stats) : null,
    landPrice
  ])
  const compressed = [
    data.cash,
    data.date.toJSON(),
    compressedParcels,
  ];
  return compressed;
};


const decompressStat = arr => arr.map(stat =>
  ['id', 'volume', 'percentage'].reduce((acc, curr, index) => {
    acc[curr] = stat[index];
    return acc;
  }, {})
);

export const processGameAfterLoad = (rawData) => {

  const data = JSON.parse(rawData);
  const cash = data[0];
  const date = Temporal.PlainDate.from(data[1]);
  const parcels = data[2];

  const decompressParcels = parcels.map(parcel => ({ id: parcel[0], crop: parcel[1], stats: parcel[2] ? decompressStat(parcel[2]) : null, landPrice: parcel[3] }))

  const gameData = {
    cash,
    date,
    parcels: decompressParcels,
  };

  return gameData;
};