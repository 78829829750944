import { usePlayerContext } from '../context/PlayerContext';
import Parcel, { ParcelCanvas } from './Parcel';

import style from './Field.module.css';

const Field = () => {
  const { gameData: { parcels } } = usePlayerContext();

  return <div className={style.field}>
    {parcels.map((parcel) => <Parcel
      key={`parcel-${parcel.id}`}
      parcel={parcel}
      canvas={<ParcelCanvas {...{ stats: parcel.stats, id: parcel.id }} />}
    />)}
  </div>
}

export default Field;