import { useMemo } from 'react';
import './Numbers.css';
import TimerContextProvider from "./actions/useGameTimer";
import { Cells } from "./components/Cells";
import { GameStats } from "./components/GameStats";
import { Settings } from "./components/Settings";
import { Targets } from "./components/Targets";
import BoardContextProvider from './context/BoardContext';
import TargetContextProvider from './context/TargetContext';
import { useSearchParams } from 'react-router-dom';

const Game = ({ size }) => {
  return (<div className='gameWrapper'>
    <div className='targetsWrapper'>
      <GameStats {...{ size }} />
      <Targets />
    </div>
    <div className='boardWrapper'>
      <Cells />
    </div>
  </div>
  );
};

const BOARD_SIZE_PARAM = 'boardSize';

export const NumberBoard = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const size = useMemo(() => {
    const param = searchParams.get(BOARD_SIZE_PARAM);
    return param ? parseInt(searchParams.get(BOARD_SIZE_PARAM), 10) : 3;
  }, [searchParams]);

  const setSizeParam = (size) => {
    const params = new URLSearchParams(`?${BOARD_SIZE_PARAM}=${size}`);
    setSearchParams(params);
  }

  return (
    <TargetContextProvider>
      <BoardContextProvider>
        <TimerContextProvider>
          <div className='numberGameWrapper'>
            <div className='numberGameContent'>
              <Game {...{ size }} />
              <Settings {...{ size, setSize: setSizeParam }} />
            </div>
          </div>
        </TimerContextProvider>
      </BoardContextProvider>
    </TargetContextProvider>);
};