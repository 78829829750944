import { createContext, useContext, useState } from 'react';
import { SaveModal } from './SaveModal';
import { LoadModal } from './LoadModal';

export const SaveLoadContext = createContext({ devMode: false, setDevMode: () => { } });

export const useSaveLoadContext = () => useContext(SaveLoadContext);

const SaveLoadContextProvider = ({ children }) => {
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);

  const [saveData, setSaveData] = useState(null);
  const [loadedData, setLoadedData] = useState(null);

  const openSaveModal = (data) => {
    setShowSaveModal(true);
    setShowLoadModal(false);
    setSaveData(data)
  }

  const openLoadModal = () => {
    closeModal();
    setShowSaveModal(false);
    setShowLoadModal(true);
    setLoadedData(null);
  }

  const closeModal = () => {
    setShowSaveModal(false);
    setShowLoadModal(false);
    setSaveData(null);
    setLoadedData(null);
  }

  return <SaveLoadContext.Provider value={{
    openSaveModal,
    openLoadModal,
    closeModal,
    loadedData,
  }}>
    {showSaveModal ? <SaveModal data={saveData} /> : null}
    {showLoadModal ? <LoadModal loadHandler={(data) => setLoadedData(data)} /> : null}
    {children}
  </SaveLoadContext.Provider>
}
export default SaveLoadContextProvider;