import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import { ENABLED_GAMES } from './constants';
import DevProvider from './context/dev-theme';
import { SplashPage } from './splash/SplashPage';
import SaveLoadContextProvider from './save-load/ModalContext';

const router = createBrowserRouter([
  {
    path: "/",
    element: <SplashPage />,
  },
  ...ENABLED_GAMES.map(game => ({
    path: game.route,
    element: game.component,
  })),
])


function App() {
  return (
    <div className="App">
      <DevProvider>
        <SaveLoadContextProvider>
          <RouterProvider router={router} />
        </SaveLoadContextProvider>
      </DevProvider>
    </div>
  );
}

export default App;
