import { ModalWrapper } from './ModalWrapper';

export const SaveModal = ({ data = null }) => {

  const createSaveLink = async (blob, type = 'json') => {
    try {
      const saveDate = new Date().toISOString();
      const fileName = window.URL.createObjectURL(new Blob([JSON.stringify(blob)], { type: "application/json" }));
      const link = document.createElement('a');
      link.download = `SaveGame${saveDate}.${type}`;

      link.href = fileName;

      link.click();
    } catch (error) {
      console.error("Save File Error:", error);
    }
  }

  // const postJSON = async (data) => {
  //   let response;
  //   try {
  //     const saveDate = new Date().toISOString()
  //     response = await fetch("http://localhost:3001/web/token/save", {
  //       method: "POST", // or 'PUT'
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         date: saveDate,
  //         data
  //       }),
  //     });

  //     const blob = await response.blob();
  //     await createSaveLink(blob);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // }

  const downloadJson = async (data) => {
    await createSaveLink(data);
  }

  const handleJSONdownload = (e) => {
    e.preventDefault();
    downloadJson(data);
  }

  return (
    <ModalWrapper>
      <h2>Save Game</h2>
      <ol>
        <li>Download a SaveGame file.</li>
        <li>To start where you left off, Upload the File.</li>
      </ol>
      {/* {data ? <button download onClick={(e) => handleSave(e)}>Save Now</button> : null} */}
      {data ? <button download onClick={(e) => handleJSONdownload(e)}>Download Game File</button> : null}
    </ModalWrapper>
  );
}