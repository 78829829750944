import style from './ChangeRate.module.css';

export const isChangeRatePositive = changeRate => changeRate > 0;
export const changeRateSym = changeRate => isChangeRatePositive(changeRate) ? '+' : '-';

export const ChangeRate = ({ changeRate }) => {
  const isPositive = isChangeRatePositive(changeRate);
  return (<span className={isPositive ? style.add : style.subtract}>
    {isPositive ? '+' : null}
    {changeRate}
  </span>);
}