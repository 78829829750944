import { Table } from "./parts/Table";
import { Title } from "./parts/Title";
import { CropBadge } from "./parts/badges/CropBadge";
import { Container } from "./wrappers/Container";
import { useInteractiveContext } from "../context/InteractiveContext";
import { CROPS } from "../constants";

import style from './Market.module.css';
import styleLand from '../Land.module.css';
import { MineralBadge } from "./parts/badges/MineralBadge";
import { changeRateSym } from "./parts/badges/ChangeRate";
import { dollarsToReadableK } from "./Bank";

export const Market = () => {
  const { selectedSeed, setSelectedSeed } = useInteractiveContext();

  const crops = CROPS.sort((a, b) => b.seedPrice - a.seedPrice);

  const handleCropClick = (e, crop) => {
    e.preventDefault();
    const isSelected = selectedSeed?.id === crop.id;
    if (isSelected) {
      setSelectedSeed(null);
    } else {
      setSelectedSeed(crop);
    }
  }

  const marketTable = {
    cols: ['Sym', 'Crop', 'Days', 'Seed', 'Sale', 'Mnrls'],
    rows: crops.map(crop => {
      const { id, ready, seedPrice, harvestPrice, minerals } = crop;
      const isSelected = selectedSeed?.id === id;
      return [
        <CropBadge id={id} onClick={(e) => handleCropClick(e, crop)} addClassName={`${style.clickable} ${isSelected ? styleLand.selected : ""}`} />,
        id,
        ready,
        dollarsToReadableK(seedPrice),
        dollarsToReadableK(harvestPrice),
        <div className={style.mineralBadgeCell}>
          {minerals.map(({ id, changeRate }) => <MineralBadge id={id} overrideClass={style.mineralBadge}>{changeRateSym(changeRate)} </MineralBadge>)}
        </div>
      ];
    }),
  }

  return (<Container>
    <Title>Market</Title>
    <Table  {...marketTable} />
  </Container>)
}