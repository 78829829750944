export const calcClickable = (cellKey, rowLength) => {
  const north = cellKey - rowLength;
  const south = cellKey + rowLength;
  const east = cellKey + 1;
  const west = cellKey - 1;

  const results = [];
  results.push(north);
  results.push(south);

  if (east % rowLength !== 0) {
    results.push(east);
  }

  if (west % rowLength !== rowLength - 1) {
    results.push(west);
  }

  results.push(cellKey);
  return results;
}

export const setClickable = (arrayOfClickable = [], setBoardData) => {
  setBoardData(prev => {
    for (const key in prev) {
      prev[key].clickable = arrayOfClickable.includes(parseInt(key, 10));
    }
    return prev;
  });
};

export const clearClickable = (setBoardData) => {
  setBoardData(prev => {
    for (const key in prev) {
      prev[key].clickable = true;
    }
    return prev;
  });
}

export const hasTarget = (cell) => Boolean(cell.target);