import { hasTarget } from "./CellActions";

export const calcBoardSize = ({ boardData }) => {
  const boardList = Object.values(boardData);
  const totalCellCount = boardList.length;
  const rowLength = Math.sqrt(totalCellCount);

  return { totalCellCount, rowLength }
}
// states
export const firstCell = (targetData) => {
  return Object.values(targetData).some(({ active }) => active);
}
export const findActive = (targetData) => {
  const activeTarget = Object.values(targetData).find(({ active }) => active) ?? null;
  return activeTarget;
}

export const previewCount = (cell, target, additionalValue = 0) => {
  return target.count + cell.value + additionalValue;
};
export const isOverGoal = (cell, target, additionalValue = 0) => {
  const previewGoal = previewCount(cell, target, additionalValue);
  return previewGoal > target.goal;
};

export const toggleTargetActive = (target, setTargetData) => {
  setTargetData(prev => ({
    ...prev,
    [target.id]: { ...prev[target.id], active: !prev[target.id].active },
  }));
}

export const findNext = (cell, targetData) => {
  const activeTarget = findActive(targetData);

  if (!activeTarget) {
    return Object.values(targetData).find(target => target.count !== target.goal && !isOverGoal(cell, target));
  }

  const simpleNext = targetData[activeTarget.id + 1];

  if (!simpleNext) {
    return null;
  }

  const targets = Object.values(targetData);
  const realNext = targets.findIndex(({ goal, count }) => {
    return goal > activeTarget.count && goal !== count;
  });

  return targetData[realNext];

  //  Depricated. Keep for legacy inspo. I swear this worked, then suddenly it was skipping over the next target
  // if (isOverGoal(cell, simpleNext, activeTarget.count)) {
  //   let i = simpleNext.id;
  //   do {
  //     i++;
  //   } while (isOverGoal(cell, targetData[i]));

  //   if (i > targetData.length) {
  //     return null;
  //   } else {
  //     return targetData[i];
  //   }
  // } else {
  //   return simpleNext;
  // }
}

export const whatToDo = (cell, target) => {
  const preview = previewCount(cell, target);
  if (cell.target === target.id) {
    console.log('\n CLEAR IT');
    return 'CLEAR';
  }
  if (hasTarget(cell)) {
    console.log('\n OVERWRITE IT');
    return 'OVERWRITE';
  }
  if (preview === target.goal) {
    console.log('\n LOCK IT');
    return 'LOCK';
  }
  if (isOverGoal(cell, target)) {
    console.log('\n MOVE IT');
    return 'MOVE';
  }
  if (!isOverGoal(cell, target)) {
    console.log('\n ADD IT');
    return 'ADD';
  }
}

// Single Actions
const clearTarget = (target, setTargetData) => {
  const newTarget = {
    ...target,
    count: 0,
    cells: [],
    active: false,
  };
  setTargetData(prev => ({
    ...prev,
    [target.id]: newTarget,
  }));
};

const clearAllCells = (targetId, setBoardData) => {
  setBoardData(prev => {
    for (const key in prev) {
      if (prev[key].target === targetId) {
        prev[key].target = null;
      }
    }
    return prev;
  });
};

const setCell = (cell, target, setBoardData) => {
  const newCell = { ...cell, target: target.id };
  setBoardData(prev => ({
    ...prev,
    [cell.id]: newCell,
  }));
};

const setTarget = (target, cells, setTargetData) => {
  const sum = cells.reduce((prev, curr) => curr.value + prev, 0);
  const newTarget = {
    ...target,
    count: target.count += sum,
    cells: [...target.cells, ...cells],
    active: true,
  };
  setTargetData(prev => ({
    ...prev,
    [target.id]: newTarget,
  }));
};



// Serial Actions
export const addIt = (cell, target, setTargetData, setBoardData) => {
  setCell(cell, target, setBoardData);
  setTarget(target, [cell], setTargetData);
};

export const clearIt = (target, setTargetData, setBoardData) => {
  clearAllCells(target.id, setBoardData);
  clearTarget(target, setTargetData);
};

export const moveIt = (fromTarget, toTarget, setTargetData, setBoardData) => {
  setTargetData(prev => {
    return {
      ...prev,
      [toTarget.id]: {
        ...prev[toTarget.id],
        cells: fromTarget.cells,
        count: fromTarget.count,
        active: true,
      },
      [fromTarget.id]: {
        ...prev[fromTarget.id],
        cells: [],
        count: 0,
        active: false,
      },
    }
  });

  setBoardData(prev => {
    for (const key in prev) {
      if (prev[key].target === fromTarget.id) {
        prev[key].target = toTarget.id;
      }
    }
    return prev;
  });
};

export const isComplete = (targetData) => {
  return Object.values(targetData).every(({ count, goal }) => count === goal);
}