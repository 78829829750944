import { useSaveLoadContext } from '../save-load/ModalContext';
import style from './Land.module.css';
import Field from './components/Field';
import { Footer } from './components/Footer';
import { MarketBar } from './components/MarketBar';
import { MobileSidebar } from './components/MobileSideBar';
import { PlotBar } from './components/PlotBar';
import { SeedBar } from './components/SeedBar';
import { Button } from './components/parts/Button';
import { InteractiveContextProvider, useInteractiveContext } from './context/InteractiveContext';
import { MarketContextProvider, useMarketContext } from './context/MarketContext';
import { PlayerContextProvider, usePlayerContext } from './context/PlayerContext';

const DesktopSidebar = () => {
  const { showMarket } = useMarketContext();
  const { selectedSeed } = useInteractiveContext();
  return (<div className={style.hideMobile}>
    <PlotBar />
    {showMarket ? <MarketBar /> : null}
    {selectedSeed ? <SeedBar /> : null}
  </div>)
}

const Page = () => {
  const { gameData } = usePlayerContext();


  if (!gameData) {
    return <Welcome />;
  }
  return (<>
    <DesktopSidebar />
    <Field />
    <MobileSidebar />
  </>)
}

const Welcome = () => {
  const { newGame } = usePlayerContext();
  const { openLoadModal } = useSaveLoadContext();

  return (
    <div className={style.welcome}>
      <div className={style.welcomeContent}>
        <h2>Land, Hoe</h2>
        <div>
          <Button onClick={() => newGame()}>Start a new game</Button>
          <Button onClick={() => openLoadModal()}>Load a Game</Button>
        </div>
        <Footer>Splash Screen</Footer>
      </div>
    </div>
  )
}

function App() {

  return (
    <div className={style.Land}>
      <PlayerContextProvider >
        <InteractiveContextProvider>
          <MarketContextProvider>
            <Page />
          </MarketContextProvider>
        </InteractiveContextProvider>
      </PlayerContextProvider >
    </div >
  );
}

export default App;
