import { createContext, useContext, useState } from 'react';

export const targetColor = {
  1: 'red',
  2: 'green',
  3: 'blue',
  4: 'yellow',
  5: 'purple',
}
const defaultTargetData = {
  1: { id: 1, count: 0, goal: 10, cells: [], active: false },
  2: { id: 2, count: 0, goal: 15, cells: [], active: false },
  3: { id: 3, count: 0, goal: 20, cells: [], active: false },
}

export const TargetContext = createContext({ targetData: defaultTargetData, setTargetData: () => { } });

export const useTargetContext = () => useContext(TargetContext);

const TargetContextProvider = ({ children }) => {
  const [targetData, setTargetData] = useState(defaultTargetData);
  return <TargetContext.Provider value={{ targetData, setTargetData }}>{children}</TargetContext.Provider>
}
export default TargetContextProvider;