import cropsJson from './crops.json';
export const TOTAL_PARCELS = 100;
export const RENDER_PIXEL_MAP = true;

export const ID = "ID";
export const CARBON = 'Carbon'
export const HYDROGEN = 'Hydrogen'
export const OXYGEN = 'Oxygen'
export const PHOSPHORUS = 'Phosphorus'
export const POTASSIUM = 'Potassium'
export const NITROGEN = 'Nitrogen'
export const SULFUR = 'Sulfur'
export const CALCIUM = 'Calcium'
export const IRON = 'Iron'
export const MAGNESIUM = 'Magnesium'
export const BORON = 'Boron'
export const MANGANESE = 'Manganese'
export const COPPER = 'Copper'
export const ZINC = 'Zinc'
export const MOLYBDENUM = 'Molybdenum'
export const CHLORINE = 'Chlorine'

export const CHEMICALS = {
  CARBON: {
    id: CARBON,
    SYM: 'C',
    CSS_COLOR: 'black',
    RARITY: 2,
  },
  HYDROGEN: {
    id: HYDROGEN,
    SYM: 'H',
    CSS_COLOR: 'blue',
    RARITY: 3,
  },
  OXYGEN: {
    id: OXYGEN,
    SYM: 'O',
    CSS_COLOR: 'lightblue',
    RARITY: 4,
  },
  PHOSPHORUS: {
    id: PHOSPHORUS,
    SYM: 'P',
    CSS_COLOR: 'ivory',
    RARITY: 4,
  },
  POTASSIUM: {
    id: POTASSIUM,
    SYM: 'K',
    CSS_COLOR: 'goldenrod',
    RARITY: 1,
  },
  NITROGEN: {
    id: NITROGEN,
    SYM: 'N',
    CSS_COLOR: 'yellow',
    RARITY: 4,
  },
  SULFUR: {
    id: SULFUR,
    SYM: 'S',
    CSS_COLOR: 'pink',
    RARITY: 2,
  },
  CALCIUM: {
    id: CALCIUM,
    SYM: 'Ca',
    CSS_COLOR: 'seashell',
    RARITY: 3,
  },
  IRON: {
    id: IRON,
    SYM: 'Fe',
    CSS_COLOR: 'red',
    RARITY: 4,
  },
  MAGNESIUM: {
    id: MAGNESIUM,
    SYM: 'Mg',
    CSS_COLOR: 'grey',
    RARITY: 4,
  },
  BORON: {
    id: BORON,
    SYM: 'B',
    CSS_COLOR: 'purple',
    RARITY: 3,
  },
  MANGANESE: {
    id: MANGANESE,
    SYM: 'Mn',
    CSS_COLOR: 'green',
    RARITY: 3,
  },
  COPPER: {
    id: COPPER,
    SYM: 'Cu',
    CSS_COLOR: 'Sienna',
    RARITY: 1,
  },
  ZINC: {
    id: ZINC,
    SYM: 'Zn',
    CSS_COLOR: 'maroon',
    RARITY: 2,
  },
  MOLYBDENUM: {
    id: MOLYBDENUM,
    SYM: 'Mo',
    CSS_COLOR: 'dimgrey',
    RARITY: 2,
  },
  CHLORINE: {
    id: CHLORINE,
    SYM: 'Cl',
    CSS_COLOR: 'limegreen',
    RARITY: 1,
  }
};


export const CORN = "Corn";
export const COTTON = "Cotton";
export const APPLES = "Apples";
export const SOYBEANS = "Soybeans";
export const POTATOES = "Potatoes";
export const TOMATOES = "Tomatoes";
export const SORGHUM = "Sorghum";
export const GRAPES = "Grapes";
export const ORANGES = "Oranges";
export const LETTUCE = "Lettuce";
export const SUGAR = "Sugar";
export const WHEAT = "Wheat";
export const HAY = "Hay";
export const ALMONDS = "Almonds";
export const STRAWBERRIES = "Strawberries";
export const RICE = "Rice";
export const PISTACHIOS = "Pistachios";
export const PEANUTS = "Peanuts";
export const BEETS = "Beets";
export const BEANS = "Beans";
export const BLUEBERRIES = "Blueberries";
export const SQUASH = "Squash";


export const CROPS = cropsJson;

export const CROP_KEY_LIST = [
  CORN,
  COTTON,
  APPLES,
  SOYBEANS,
  POTATOES,
  TOMATOES,
  SORGHUM,
  GRAPES,
  ORANGES,
  LETTUCE,
  SUGAR,
  WHEAT,
  HAY,
  ALMONDS,
  STRAWBERRIES,
  RICE,
  PISTACHIOS,
  PEANUTS,
  BEETS,
  BEANS,
  BLUEBERRIES,
  SQUASH,
]
