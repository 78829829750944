import { useInteractiveContext } from "../context/InteractiveContext";
import { Button } from './parts/Button';
import { Table } from "./parts/Table";
import { Title } from "./parts/Title";
import { CropBadge } from "./parts/badges/CropBadge";
import { MineralBadge } from "./parts/badges/MineralBadge";
import { Container } from "./wrappers/Container";

import { useMemo } from "react";
import styleLand from '../Land.module.css';
import { useMarketContext } from "../context/MarketContext";
import { usePlayerContext } from "../context/PlayerContext";
import { dollarsToReadable } from "./Bank";
import { findCropData } from "./Crop";
import style from './Seed.module.css';
import { ChangeRate } from "./parts/badges/ChangeRate";

export const Seed = () => {
  const { selectedSeed, setSelectedSeed, selectedParcelId } = useInteractiveContext();
  const { setShowMarket } = useMarketContext();
  const { sowCrop } = usePlayerContext();

  const cropData = findCropData({ id: selectedSeed.id });

  const seedTable = useMemo(() => ({
    cols: ['Sym', 'Mineral', 'Change'],
    rows: cropData.minerals.map(mineral => {
      const { id, changeRate } = mineral;
      return [<MineralBadge id={id} />, id, <ChangeRate changeRate={changeRate} />];
    }),
  }), [cropData.minerals]);

  const handleSow = (e) => {
    e.preventDefault();
    sowCrop({ cropId: cropData.id, parcelId: selectedParcelId });
    setSelectedSeed(null);
    setShowMarket(false);
  }

  return (<Container>
    <Title>
      <CropBadge id={cropData.id} addClassName={style.badge} />
      <div className={styleLand.spaceBetween}>
        <div>{cropData.id}</div>
        <div className={styleLand.dim}>{dollarsToReadable(cropData.seedPrice)}</div>
      </div>
    </Title>
    <div className={style.details}>
      <div>{cropData.ready} days</div>
      <div>{dollarsToReadable(cropData.harvestPrice)} Max Sale</div>
    </div>
    <Table {...seedTable} />

    <Button onClick={(e) => handleSow(e)}>Purchase</Button>
  </Container>);

};