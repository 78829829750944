import { useState } from 'react';
import { processGameAfterLoad } from '../land/save-load-processer';
import { ModalWrapper } from './ModalWrapper';

// const loadJSON = async (data) => {
//   try {
//     const response = await fetch(process.env.REACT_APP_LOAD_API, {
//       method: "GET", // or 'PUT'
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//     });

//     const result = await response.json();
//     console.log("Success:", result);
//   } catch (error) {
//     console.error("Error:", error);
//   }
// }

export const LoadModal = ({ loadHandler = null }) => {
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      const loadedFile = e.target.result;
      setUploadedFile(loadedFile);

    };
  }

  const handleFile = (e) => {
    e.preventDefault();
    const loadedData = processGameAfterLoad(uploadedFile);

    if (loadHandler) {
      loadHandler(loadedData);
    }
  }

  return (
    <ModalWrapper>
      <h2>Load Game</h2>
      <ol>
        <li>Upload File</li>
        <li>Resume Game</li>
      </ol>
      <input id="file" name="file" type="file" onChange={e => handleFileChange(e)} />
      <br />
      <br />
      <button onClick={(e) => handleFile(e)}>Apply File</button>
    </ModalWrapper>
  );
}