import styleLand from '../Land.module.css';
import { CROPS } from "../constants";
import style from './Seed.module.css';
import { Button } from "./parts/Button";
import { Table } from "./parts/Table";
import { SubTitle } from "./parts/Title";
import { ChangeRate } from "./parts/badges/ChangeRate";
import { CropBadge } from "./parts/badges/CropBadge";
import { MineralBadge } from "./parts/badges/MineralBadge";
import { Container } from "./wrappers/Container";


export const findCropData = ({ id }) => CROPS.find(crop => crop.id === id);

export const Crop = ({ id, daysRemaining, health = 0, estSalePrice }) => {

  const cropData = findCropData({ id });

  const cropTable = {
    cols: ['Sym', 'Mineral', 'Change'],
    rows: cropData.minerals.map(mineral => {
      const { id, changeRate } = mineral;
      return [<MineralBadge id={id} />, id, <ChangeRate {...{ changeRate }} />];
    }),
  }

  return (<Container>
    <SubTitle>
      <CropBadge id={cropData.id} addClassName={style.badge} />
      <div className={styleLand.spaceBetween}>
        <div>{cropData.id}</div>
        <div className={styleLand.dim}>{health}%</div>
      </div>
    </SubTitle>
    <div className={style.details}>
      <div>{daysRemaining} days remaining</div>
      <div>${estSalePrice}</div>
    </div>
    <Table {...cropTable} />

    <Button>Harvest & Sell</Button>
  </Container>)

};