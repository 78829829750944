// TILES
const GROUND = 'ground';
const ROCK = 'rock';
const TREE = 'tree';
const MUSHROOM_YELLOW = 'mushroomYellow';
const LILY_PURPLE = 'lilyPurple';
const LILY_BLUE = 'lilyBlue';

export const RARITY_MATRIX = {
  16: [GROUND],
  8: [TREE],
  4: [],
  2: [MUSHROOM_YELLOW],
  1: [LILY_PURPLE, LILY_BLUE]
}
export const COLLECTABLES = [LILY_PURPLE, MUSHROOM_YELLOW, LILY_BLUE].sort();
export const FORBID_CELLS = [TREE, ROCK];

export const idToSym = {
  [TREE]: '🌳',
  [ROCK]: '🪨',
  [LILY_BLUE]: '֍',
  [LILY_PURPLE]: '֍',
  [MUSHROOM_YELLOW]: '྿',
  [GROUND]: '',
}

export const LEVELS = {
  1: {
    [MUSHROOM_YELLOW]: 1,
    [LILY_PURPLE]: 1,
  },
  2: {
    [MUSHROOM_YELLOW]: 15,
    [LILY_PURPLE]: 5,
    [LILY_BLUE]: 5,
  },
  3: {
    [MUSHROOM_YELLOW]: 40,
    [LILY_PURPLE]: 20,
    [LILY_BLUE]: 5,
  },
}