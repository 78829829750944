import { getRandomInt } from "./generateInt";

export const getRandomArray = (sourceArray, requestedCount) => {
  const result = [];
  const usedIndex = [];
  do {
    const randomIndex = getRandomInt(0, sourceArray.length);
    if (usedIndex.includes(randomIndex)) {
      continue;
    }
    usedIndex.push(randomIndex);
    result.push(sourceArray[randomIndex]);
  } while (result.length < requestedCount);

  return result;
}