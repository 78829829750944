import './Targets.css';
import { useTargetContext } from '../context/TargetContext';

const Target = ({ id, count, goal, active }) => {
  const fillWidthPerc = (count / goal) * 100;
  return (<li key={id} className={`target ${active ? 'active' : ''}`}>
    <div className={`targetText target${id}`} >{count}/{goal}</div>
    <div className='targetBar'>
      <div className={`targetFill target${id}`} style={{ width: `${fillWidthPerc}%` }} />
      <div className={`targetBackground target${id}`} />
    </div>
  </li>)
}
export const Targets = () => {
  const { targetData } = useTargetContext();
  const targetList = Object.values(targetData);

  return (<div className={`targetList targetList${targetList.length}`}>
    {targetList.map((target) => <Target key={target.id} {...target} />)}
  </div>)
};