import { useSaveLoadContext } from './ModalContext';
import style from './SaveLoad.module.css';

export const ModalWrapper = ({ children }) => {
  const { closeModal } = useSaveLoadContext();
  return (
    <div className={style.modalBackground}>
      <div className={style.modalForeground}>
        {children}
        <button onClick={() => closeModal()}>Close</button>
      </div>
    </div>
  );
}