import { getRandomInt } from "../../rarity-test/calculations"
import { calcClickable } from "./CellActions";

const getXNumbers = (gridFactor) => {
  const totalCells = gridFactor * gridFactor;
  const cells = [];
  for (let i = 0; i < totalCells; i++) {
    cells.push(getRandomInt(0, 20));
  }
  return cells;
}

const cellTemplate = { id: 1, target: null, clickable: true, value: 1 };


export const generateBoard = (gridSize = 3) => {
  const gridFactor = gridSize;
  const gridValues = getXNumbers(gridFactor);
  const valueArray = gridValues.map((val, index) => ({ ...cellTemplate, id: index, value: val }));

  const board = {};

  // set IDs
  valueArray.forEach(cell => {
    board[cell.id] = cell;
  });

  return board;
}

const targetTemplate = { id: 1, count: 0, goal: 10, cells: [], active: false };


const findSeedCell = (remainingCells) => {
  const seedCellIndex = getRandomInt(0, remainingCells.length);
  const seedCell = remainingCells.splice(seedCellIndex, 1);
  return seedCell[0];
}

const findNextCell = (size, cellList, remainingCells) => {
  const options = calcClickable(cellList.at(-1), size);
  const availableOptions = options.filter(id => remainingCells.includes(id));
  const next = availableOptions[getRandomInt(0, availableOptions.length)];
  return next;
}

const applyNextCell = (next, remainingCells) => {
  const nextIndex = remainingCells.findIndex(element => element === next);
  const nextCell = remainingCells.splice(nextIndex, 1);
  return nextCell[0];
}

const getNextCell = (size, cellList, remainingCells) => {
  const next = findNextCell(size, cellList, remainingCells);
  if (!next) {
    const next = findNextCell(size, cellList.toReversed(), remainingCells);
    if (!next) return;
    return applyNextCell(next, remainingCells);
  };
  return applyNextCell(next, remainingCells);
}

const findTargetEndCells = (target) => [target.cells.at(0), target.cells.at(-1)];

const findAdjacentEnds = (cell, size, targets) => {
  const options = calcClickable(cell, size);
  // find target that contains adjacent cell on an end, and which end
  const adjacentTargetIndex = Object.values(targets).findIndex(target => {
    const cellEnds = findTargetEndCells(target);
    const validEnd = options.find(option => cellEnds.includes(option));;
    if (validEnd) return true;
    else return false;
  });
  if (adjacentTargetIndex > -1) {
    const adjacentTarget = targets[adjacentTargetIndex];
    const targetEndCells = findTargetEndCells(adjacentTarget);
    const targetEndCellsIndex = targetEndCells.findIndex(cellId => options.includes(cellId));
    if (targetEndCellsIndex === 1) {
      targets[adjacentTargetIndex].cells.push(cell);
    } else {
      targets[adjacentTargetIndex].cells.unshift(cell);
    }
  }
};

const genTargets = (size, board) => {
  const maxCellCount = size * size;
  const remainingCells = Array.from({ length: maxCellCount }, (value, index) => index);

  const targets = {};
  for (let i = 0; i < size; i++) {
    targets[i] = { ...targetTemplate, id: i, cells: [findSeedCell(remainingCells)] };
  }

  for (let i = 0; i <= 3; i++) {
    Object.values(targets).forEach(({ id, cells }) => {
      const nextCell = getNextCell(size, cells, remainingCells);
      if (!nextCell) return;
      targets[id].cells.push(nextCell);
    });
  }

  // Assign stray cells
  remainingCells.forEach(cellId => findAdjacentEnds(cellId, size, targets));

  // get totals
  Object.values(targets).forEach(({ cells }, index) => {
    const sum = cells.reduce((acc, curr) => acc + board[curr].value, 0);
    targets[index].goal = sum;
  })

  return targets;
}

export const generateBoardAndTarget = (size) => {
  const board = generateBoard(size);
  const targets = genTargets(size, board);
  return { board, targets };
}

export const resetBoard = (boardData) => {
  const clonedBoardData = { ...boardData };
  Object.values(clonedBoardData).forEach(({ id }) => { clonedBoardData[id].target = null });

  return clonedBoardData;
};
export const resetTargets = (targetData) => {
  const clonedTargetData = { ...targetData };
  Object.values(clonedTargetData).forEach(({ id }) => {
    clonedTargetData[id].cells = [];
    clonedTargetData[id].count = 0;
  });

  return clonedTargetData;
};