import { Temporal } from "@js-temporal/polyfill";
import { ScavengerHunt } from "./scavenger-hunt/ScavengerHunt";
import Land from "./land/Land";
import { Rarity } from "./rarity-test/Rarity";
import { NumberBoard } from "./number/Number";

export const GAME_KEYS = {
  HUNT: { key: 'HUNT', route: '/hunt', component: <ScavengerHunt />, name: 'Hunt' },
  NUMBERS: { key: 'NUMBERS', route: '/numbers', component: <NumberBoard />, name: "Numbers" },
  LAND: { key: 'LAND', route: '/land', component: <Land />, name: "Land, Ho" },
  RARE: { key: 'RARE', route: '/rareity', component: <Rarity />, name: "Rarity Woods" }
};

export const TIME_ZONE = 'America/New_York';
export const TIME_ZONE_TEMPORAL = Temporal.TimeZone.from(TIME_ZONE);

const REACT_APP_ENABLED_GAMES = process.env?.REACT_APP_ENABLED_GAMES ?? [];
export const ENABLED_GAMES = Object.values(GAME_KEYS).filter(({ key }) => REACT_APP_ENABLED_GAMES.includes(key));

export const DEV_MODE = process.env?.REACT_APP_ENABLED_DEV_MODE ?? false;