import { Plot } from "./Plot";
import { TabBar } from "./TabBar";
import Bank from './Bank';
import { Market } from './Market';
import { Seed } from './Seed';
import { Panel } from './wrappers/Panel';
import { useInteractiveContext } from '../context/InteractiveContext';
import { useMarketContext } from '../context/MarketContext';
import { SplashContent } from "./PlotBar";
import { Footer } from "./Footer";

import styleLand from '../Land.module.css';
import style from './MobileSideBar.module.css';


const TabSet = () => {
  const { selectedSeed } = useInteractiveContext();
  const { showMarket } = useMarketContext();

  const tabList = [{
    tabHeader: 'Plot',
    tabContent: <Plot />,
    enabled: true,
  },
  {
    tabHeader: 'Market',
    tabContent: showMarket ? <Market /> : null,
    enabled: showMarket,
  },
  {
    tabHeader: 'Seed',
    tabContent: selectedSeed ? <Seed /> : null,
    enabled: selectedSeed,
  }];

  const activeTab = selectedSeed ? 2 : showMarket ? 1 : 0

  return (<TabBar tabList={tabList} activeTab={activeTab} />)
}

export const MobileSidebar = () => {
  const { selectedParcelId } = useInteractiveContext();

  const contents = selectedParcelId === null
    ? <SplashContent />
    : <TabSet />;

  return (<div className={`${styleLand.hideDesktop} ${style.sidebar} ${selectedParcelId ? style.expandSidebar : ""}`}>
    <Panel>
      <Bank />
      {contents}
      <Footer>This is Mobile</Footer>
    </Panel>
  </div>)
}