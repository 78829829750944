import React, { useContext, useState } from "react";

const defaultContext = {
  selectedParcelId: null,
  setSelectedParcelId: () => { },
  selectedSeed: null,
  setSelectedSeed: () => { },
}

export const InteractiveContext = React.createContext(defaultContext);

export const useInteractiveContext = () => {
  const interactiveContext = useContext(InteractiveContext);
  return interactiveContext;
}

export const InteractiveContextProvider = ({ children }) => {
  const [selectedParcelId, setSelectedParcelId] = useState(null);
  const [selectedSeed, setSelectedSeed] = useState(null);

  return (
    <InteractiveContext.Provider value={{
      selectedParcelId, setSelectedParcelId,

      selectedSeed, setSelectedSeed
    }}>
      {children}
    </InteractiveContext.Provider>
  );
}