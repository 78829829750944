import { useEffect, useState } from "react";
import primaryClueList from './clues.json';
import { Clue } from "./Clue";
import styles from './ScavengerHunt.module.css';

const useFetch = () => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFn = async () => {
      setIsLoading(true);
      try {

        const response = await fetch(process.env.REACT_APP_SCAVENGER_API);
        const json = await response?.json();
        setData(json?.data);
        setIsLoading(false);
      } catch (err) {
        setData(primaryClueList);
        setIsLoading(false);
      }
    }
    fetchFn();
  }, []);

  return { data, loading: isLoading };
}

const ClueList = () => {
  const { data, loading } = useFetch();
  const [level, setLevel] = useState(0);

  const advanceLevel = () => {
    setLevel((prev) => (prev + 1));
  };

  if (loading) {
    return <div>Loading</div>;
  }
  return (<div className={styles.pageContainer}>
    {data.map((props, index) => <Clue active={index === level} {...{ level, advanceLevel, ...props }} />)}
  </div>);
}

export const ScavengerHunt = () => {
  return <div className={styles.pageWrapper}>
    <ClueList />
  </div>
};