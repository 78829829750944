import { Temporal } from "@js-temporal/polyfill";
import { useState } from 'react';
import { TIME_ZONE } from "../../constants";
import { useTimerContext } from "../actions/useGameTimer";
import { useLoadLevel } from '../actions/useLoadLevel';
import './GameStats.css';
import { useTargetContext } from "../context/TargetContext";
import { isComplete } from "../actions/NewActions";

const START_DATE = '2024-05-09';

const formatDate = (date) => {
  const dateJs = new Date(`${date}T00:00:00`);

  return Intl.DateTimeFormat("en-US", { dateStyle: "long", timeZone: TIME_ZONE }).format(dateJs);
}

const shareScore = ({ size, stringTime, date }) => {

  const cellsIcons = {
    3: '🟥 🟩 🟦',
    4: '🟥 🟩 🟦 🟨',
    5: '🟥 🟩 🟦 🟨 🟪',
  };

  alert(`${cellsIcons[size]} | ${stringTime} | ${formatDate(date)}`);
}

export const GameStats = ({ size }) => {

  const { time } = useTimerContext();
  const { targetData } = useTargetContext();
  const stringTime = time;
  // const stringTime = parseFloat((time / 10).toString()).toFixed(2);
  const displayTime = Temporal.PlainTime.from({ second: time % 60, minute: time / 60 }).toString({ smallestUnit: 'seconds', largestUnit: 'auto' });
  const sinceDays = Temporal.Now.plainDateISO().since(Temporal.PlainDate.from(START_DATE)).days;

  const [subtractDays, setSubtractDays] = useState(0);
  const { date } = useLoadLevel({ size, subtractDays });

  const complete = isComplete(targetData)

  return (
    <>
      <div className='boardStats'>

        <div className='boardStatsDate'>
          <div>
            {subtractDays < sinceDays
              ? <button className="dayNavBtn" onClick={() => setSubtractDays(prev => prev + 1)}>&#9664;</button>
              : null}
          </div>
          <div>
            <p>{formatDate(date)}</p>
          </div>
          <div>
            {subtractDays > 0
              ? <button className="dayNavBtn" onClick={() => setSubtractDays(prev => prev - 1)}>&#9654;</button>
              : null}
          </div>
        </div>
        <div>{displayTime}</div>
      </div>
      {complete && <button className="boardShare" onClick={() => shareScore({ size, stringTime, date })}>SHARE</button>}
    </>

  );
}
