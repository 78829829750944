import styles from './Title.module.css';

export const Title = ({ children }) => {
  return (<div className={styles.title}>
    {children}
  </div>);
};

export const SubTitle = ({ children }) => {
  return (<div className={styles.subTitle}>
    {children}
  </div>);
};