import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useInteractiveContext } from '../context/InteractiveContext';
import { getRandomInt } from '../utils/generateInt';
import { CropBadge } from './parts/badges/CropBadge';
import { MineralBadge } from './parts/badges/MineralBadge';

import styleLand from '../Land.module.css';
import { CHEMICALS } from '../constants';
import { dollarsToReadable } from './Bank';
import style from './Parcel.module.css';

const dotColor = (context = null, color = 'black', percentage = 0, index) => {
  const canvasWidth = context.canvas.width;
  const canvasHight = context.canvas.width;
  const totalPixels = canvasWidth * canvasHight;
  const pixelCount = Math.round(totalPixels * (percentage / 100));

  for (let i = 0; i < pixelCount; i++) {
    const xRand = getRandomInt(0, 50);
    const yRand = getRandomInt(0, 50);
    context.fillStyle = color;
    context.fillRect(xRand, yRand, 2, 2);
  }
}

export const ParcelCanvas = ({ width = 100, height = 100, stats = [], id }) => {
  const canvasRef = useRef(null)

  const newStats = useMemo(() => [...stats].sort((a, b) => a.volume - b.volume), [stats]);


  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    context.fillStyle = 'brown'
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    newStats.forEach((stat, index) => dotColor(context, CHEMICALS[stat.id.toUpperCase()].CSS_COLOR, stat.percentage, index));
  }, [id, newStats])

  const props = { width, height };
  return <canvas ref={canvasRef} {...props} />;
}

export const ParcelId = ({ id }) => {
  return <div className='parcelId'>
    <div>Parcel</div>
    <div>#{id}</div>
  </div>
}

const StatBars = ({ stats }) => {
  const topThree = stats.slice(0, 3)

  return <div className={style.statBars}>
    {topThree.map(stat => <div className={style.mineralBadge}>
      <MineralBadge {...{
        id: stat.id,
        overrideClass: style.statProgress,
        overrideStyles: { width: `${stat.percentage * 2}%` }
      }} />
      <div className={style.statPercent}>{stat.volume}</div>
    </div>
    )}
  </div>
}

const Parcel = ({ parcel, canvas }) => {
  const { selectedParcelId, setSelectedParcelId } = useInteractiveContext();

  const { id, stats, crop, landPrice } = parcel;

  const isSelected = selectedParcelId === id;

  // const memoizedCanvas = useMemo(() => canvas, [])

  const onClickHandler = useCallback((e) => {
    e.preventDefault();
    if (isSelected) {
      setSelectedParcelId(null);
    } else {
      setSelectedParcelId(id);
    }
  }, [isSelected, setSelectedParcelId, id])

  const Purchased = () => (<>
    {crop
      ? <CropBadge {...crop} addClassName={style.cropBadge}>
        <div className={style.cropPercent}>{crop.health}%</div>
      </CropBadge>
      : null}
    <StatBars {...{ stats }} />
    {/* {canvas} */}
  </>)

  return useMemo(() => (
    <div className={`${style.parcel} ${isSelected ? styleLand.selected : ""}`} onClick={(e) => onClickHandler(e)}>
      <div className={style.parcelBody}>
        {landPrice
          ? <div className={style.locked}>{dollarsToReadable(landPrice)}</div> :
          <Purchased />}
      </div>
    </div>)
    , [isSelected, landPrice, onClickHandler])
}

export default Parcel;