import styles from './SplashPage.module.css';
import { GameCard } from './GameCard';
import { ENABLED_GAMES } from '../constants';

export const SplashPage = () => {
  return (<div className={styles.splashWrapper}>
    <div className={styles.splashHeader}>
      <h1>Cooknick <span className={styles.purple}>𐂂</span> Labs</h1>
      {/* https://unicodeplus.com/U+10082 */}
    </div>
    <div>
      {ENABLED_GAMES.length > 0
        ? <div className={styles.gameList}>
          {ENABLED_GAMES?.map(game => <GameCard game={game} />)}
        </div>
        : <div className={styles.noGames}> No Games Available Yet</div>}
    </div>
  </div>)
}