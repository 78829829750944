
import { useInteractiveContext } from "../context/InteractiveContext";
import { generateAllCrops } from "../generateCropMinerals";
import Bank from "./Bank";
import { Footer } from "./Footer";
import { Plot } from "./Plot";
import style from "./PlotBar.module.css";
import { Panel } from "./wrappers/Panel";

export const SplashContent = () => {
  return <div>
    <p>Select a Parcel to Begin</p>
    <button onClick={() => {
      const result = generateAllCrops();
      console.log('results', result);
    }}>Generate Crop Data</button>
  </div>
};

export const PlotBar = () => {
  const { selectedParcelId } = useInteractiveContext();

  const contents = selectedParcelId === null
    ? <div className={style.splashPage}><SplashContent /></div>
    : <Plot />;

  return (<Panel>
    <Bank />
    {contents}
    <Footer>This is Desktop</Footer>
  </Panel>);
};