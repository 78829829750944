import { generateRarityArray } from "../rarity-test/calculations";
import { CHEMICALS, CROP_KEY_LIST } from "./constants";
import { mineralRarity } from "./context/PlayerContext";
import { getRandomInt } from "./utils/generateInt";
import { getRandomArray } from "./utils/getRandomArray";

export const generateMinerals = () => {

  // const randomMineralCount = getRandomInt(3, 4);
  // const randomArray = getRandomArray(allMinerals, 4);
  const randomArray = generateRarityArray(4, mineralRarity(), true).map(({ id }) => id.toUpperCase());
  const mineralArray = randomArray.map((mineral, index) => {
    const negativeOrPositive = index === 3 ? 1 : -1;
    return { id: CHEMICALS[mineral].id, changeRate: getRandomInt(2, 20) * negativeOrPositive }
  })

  return mineralArray;
}

const generateReadyDays = () => getRandomInt(60, 140);
const generateSowMonth = () => getRandomInt(1, 12);
const generateSeedPrice = () => getRandomArray([300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200], 1);
const generateHarvestPrice = (seedPrice) => getRandomInt(2, 5) * seedPrice;


const generateCropStats = () => {
  const seedPrice = generateSeedPrice()[0]
  return {
    ready: generateReadyDays(),
    seedPrice,
    sowMonth: generateSowMonth(),
    harvestPrice: generateHarvestPrice(seedPrice),
    minerals: generateMinerals(),
  };
}

export const generateAllCrops = () => CROP_KEY_LIST.map(crop => ({
  id: crop,
  title: crop,
  ...generateCropStats()
}));