import style from './Table.module.css';

export const Table = ({ rows = [], cols, expandable = null, rowProps = {} }) => {

  const tableHeader = cols.map((col, index) => <td key={`col-${index}`}>{col}</td>);

  const tableCells = cells => cells.map((cell, index) => <td key={`cell-${index}`}>{cell}</td>);

  const tableRow = (rowCells, index) => <tr key={`row=${index}`} {...rowProps}>
    {tableCells(rowCells)}
    {expandable}
  </tr >;

  return <table className={style.globalTable}>
    <thead className={style.tableHead}>
      <tr>
        {tableHeader}
      </tr>
    </thead>
    <tbody className={style.tableBody}>
      {rows.map((row, index) => tableRow(row, index))}
    </tbody>
  </table>
}