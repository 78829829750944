import { Temporal } from "@js-temporal/polyfill";
import { useBoardContext } from '../context/BoardContext';
import { useTargetContext } from '../context/TargetContext';
import three from '../levels/3x3.json';
import four from '../levels/4x4.json';
import five from '../levels/5x5.json';
import { useCallback, useEffect, useMemo } from "react";
import { useTimerContext } from "./useGameTimer";

const mapFile = {
  3: three,
  4: four,
  5: five,
}

const sortTargets = (targets) => {
  const sorted = Object.values(targets).sort((a, b) => a.goal - b.goal);
  const reassignedSorted = sorted.map((target, index) => ({ ...target, id: index }));
  const newTargets = {};
  reassignedSorted.forEach(target => newTargets[target.id] = target);
  return newTargets;
}

export const useLoadLevel = ({ size, subtractDays = 0 }) => {
  const { setTargetData } = useTargetContext();
  const { setBoardData } = useBoardContext();
  const { resetTimer } = useTimerContext();

  const today = Temporal.Now.plainDateISO();
  const subjectDate = today.subtract({ days: subtractDays });
  const subjectDateString = subjectDate.toString()

  const data = useMemo(() => {
    const boardByDate = mapFile[size].find(({ date }) => date === subjectDateString)
    if (!boardByDate) {
      return mapFile[size].at((subtractDays + 1) * -1);
    } else {
      return boardByDate;
    }
  }, [subjectDateString, size, subtractDays]);

  const targetData = useMemo(() => sortTargets(data.targetData), [data.targetData]);
  const resetTimerCallback = useCallback(() => resetTimer(), [resetTimer]);

  useEffect(() => {

    if (data) {
      setBoardData(data.boardData);
      setTargetData(targetData);
      resetTimerCallback();
    }
  }, [
    data,
    subtractDays,
    setBoardData,
    setTargetData,
    targetData,
    resetTimerCallback,
  ])


  return data;

}