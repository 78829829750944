import { createContext, useContext, useState } from 'react';
export const DevContext = createContext({ devMode: false, setDevMode: () => { } });

export const useDevContext = () => useContext(DevContext);

const DevProvider = ({ children }) => {
  const [devMode, setDevMode] = useState(true);

  if (process.env.NODE_ENV !== 'development') {
    return children;
  }

  return <DevContext.Provider value={{ devMode, setDevMode }}>
    {/* <button className={style.button} onClick={() => setDevMode(prev => !prev)}>
      Dev Mode: {devMode
        ? <span className={style.disabled}>DISABLED</span>
        : <span className={style.enabled}>ENABLED</span>}
    </button> */}
    {children}
  </DevContext.Provider>
}
export default DevProvider;