import { createContext, useContext, useState } from 'react';

const defaultBoardData = {
  0: { id: 0, target: null, clickable: true, value: 1 },
  1: { id: 1, target: null, clickable: true, value: 2 },
  2: { id: 2, target: null, clickable: true, value: 3 },
  3: { id: 3, target: null, clickable: true, value: 4 },
  4: { id: 4, target: null, clickable: true, value: 5 },
  5: { id: 5, target: null, clickable: true, value: 6 },
  6: { id: 6, target: null, clickable: true, value: 7 },
  7: { id: 7, target: null, clickable: true, value: 8 },
  8: { id: 8, target: null, clickable: true, value: 9 },
};

export const BoardContext = createContext({ boardData: defaultBoardData, setBoardData: () => { } });

export const useBoardContext = () => useContext(BoardContext);

const BoardContextProvider = ({ children }) => {
  const [boardData, setBoardData] = useState(defaultBoardData);
  return <BoardContext.Provider value={{ boardData, setBoardData }}>{children}</BoardContext.Provider>
}
export default BoardContextProvider;