import { useEffect, useState } from 'react';
import style from './TabBar.module.css';

export const TabBar = ({ tabList = [], activeTab = 0 }) => {
  const [tab, setTab] = useState(activeTab);

  useEffect(() => {
    setTab(activeTab);
  }, [activeTab]);

  return (<div>
    <div role="tablist" className={style.TabBar}>
      {tabList.map(({ tabHeader, enabled }, index) =>
        <button
          type="button"
          role="tab"
          selected={tab === index}
          className={`${style.tab} ${tab === index ? style.activeTab : ""}`}
          onClick={() => setTab(index)}
          aria-controls={`tabpanel-${index}`}
          id={`tab-${index}`}
          key={`tab-${index}`}
          disabled={!enabled}
        >
          {tabHeader}
        </button>
      )}
    </div>
    <div>
      {tabList.map(({ tabContent }, index) =>
        <div
          id={`tabpanel-${index}`}
          key={`tabpanel-${index}`}
          role="tabpanel"
          tabIndex="0"
          className={`${style.tabPanel} ${index === tab ? style.activeTabPanel : ""}`}
          aria-labelledby={`tab-${index}`}>
          {tabContent}
        </div>
      )}
    </div>
  </div>)
}