import { Temporal } from "@js-temporal/polyfill";

const goodLevels = [];

export const saveLevel = ({ data }) => {

  const boardSize = Math.sqrt(Object.values(data.boardData).length);

  const lastLevelDate = goodLevels.at(-1)?.date ?? "2024-06-03";
  console.log('lastLevelDate', lastLevelDate);

  goodLevels.push({
    date: lastLevelDate ? Temporal.PlainDate.from(lastLevelDate).add({ days: 1 }).toString() : Temporal.Now.plainDateISO().toString(),
    boardSize,
    ...data,
  })

  console.log('goodLevels', goodLevels);
}
