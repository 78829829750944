import { useState } from 'react';
import '../Numbers.css';
import './Settings.css';
import { clearClickable } from '../actions/CellActions';
import { resetBoard, resetTargets } from '../actions/GenerateNumberLevels';
import { useTimerContext } from "../actions/useGameTimer";
import { DevControls } from '../components/DevControls';
import { useBoardContext } from '../context/BoardContext';
import { useTargetContext } from '../context/TargetContext';
import { DEV_MODE } from '../../constants';


export const Settings = ({ size, setSize }) => {
  const [devMode, setDevMode] = useState(false);
  const { targetData, setTargetData } = useTargetContext();
  const { boardData, setBoardData } = useBoardContext();
  const { resetTimer } = useTimerContext();

  const handleReset = () => {
    const clearedBoardData = resetBoard(boardData, setBoardData);
    setBoardData(clearedBoardData);

    const clearedTargetData = resetTargets(targetData);
    setTargetData(clearedTargetData);

    resetTimer();
    clearClickable(setBoardData);
  }
  return (<div className='settings'>
    <div className='settingsRow'>
      <div className='sizings'>
        <button className={`sizeBtn ${size === 3 ? 'active' : ''}`} onClick={() => setSize(3)}>3x3</button>
        <button className={`sizeBtn ${size === 4 ? 'active' : ''}`} onClick={() => setSize(4)}>4x4</button>
        <button className={`sizeBtn ${size === 5 ? 'active' : ''}`} onClick={() => setSize(5)}>5x5</button>
      </div>
      <button className="resetBtn" onClick={() => handleReset()}>Reset</button>
    </div>
    {DEV_MODE && <div>
      <button className="devControlsBtn" onClick={() => setDevMode(prev => !prev)}>Dev Mode</button>
      {devMode ? <DevControls /> : null}
    </div>}
  </div>);
};
