import React, { useContext, useState } from "react";

const defaultContext = {
  showMarket: false,
  setShowMarket: () => { },
}

export const MarketContext = React.createContext(defaultContext);

export const useMarketContext = () => {
  const interactiveContext = useContext(MarketContext);
  return interactiveContext;
}

export const MarketContextProvider = ({ children }) => {
  const [showMarket, setShowMarket] = useState(false);

  return (
    <MarketContext.Provider value={{
      showMarket,
      setShowMarket,
      // marketValues,
    }}>
      {children}
    </MarketContext.Provider>
  );
}