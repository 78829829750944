import { usePlayerContext } from '../context/PlayerContext';
import { Container } from './wrappers/Container';
import style from './Bank.module.css';
import { useSaveLoadContext } from '../../save-load/ModalContext';
import { Button } from './parts/Button';
import { processGameForSave } from '../save-load-processer';

export const dollarsToReadable = (dollars) => "$" + (dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
export const dollarsToReadableK = (dollars) => dollars > 999 ? "$" + ((dollars / 1000).toFixed(1)) + 'K' : dollarsToReadable(dollars);

export const dateToReadable = (temporalDate) => `${temporalDate.month} / ${temporalDate.day} / ${temporalDate.year}`;

const Bank = () => {
  const { gameData, pauseClock, togglePause } = usePlayerContext();
  const { cash, date } = gameData;
  const { openSaveModal } = useSaveLoadContext();

  const handleSave = () => {
    const dataForSave = processGameForSave(gameData);
    openSaveModal(dataForSave);
  }

  return <Container>
    <div className={style.bank}>
      <p><span className={style.label}>Cash:</span> {dollarsToReadable(cash)}</p>
      <p><span className={style.label}>Date:</span> {dateToReadable(date)}</p>
      <Button onClick={() => togglePause()}>{pauseClock ? "Play" : "Pause"}</Button>
      <Button onClick={() => handleSave()}>Save Game</Button>
    </div>
  </Container>
}

export default Bank;