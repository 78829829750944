import { useInteractiveContext } from '../context/InteractiveContext';
import { useMarketContext } from '../context/MarketContext';
import { usePlayerContext } from '../context/PlayerContext';
import { Crop, findCropData } from './Crop';
import { Button } from './parts/Button';
import { Table } from './parts/Table';
import { Title } from './parts/Title';
import { MineralBadge } from './parts/badges/MineralBadge';
import { Container } from './wrappers/Container';

import styleLand from '../Land.module.css';
import { dollarsToReadable } from './Bank';
import style from './Plot.module.css';
import { ChangeRate } from './parts/badges/ChangeRate';

const useSelectedParcel = () => {
  const { selectedParcelId } = useInteractiveContext();
  const { gameData: { parcels } } = usePlayerContext();

  return parcels.find(parcel => parcel.id === selectedParcelId);
}

const PlotForSale = () => {
  const { id, landPrice } = useSelectedParcel();
  const { purchaseParcel } = usePlayerContext();

  const handleClickPurchasePlot = (e) => {
    e.preventDefault();
    purchaseParcel({ parcelId: id });
  }

  return (<Button onClick={(e) => handleClickPurchasePlot(e)}>Purchase Parcel for {dollarsToReadable(landPrice)}</Button>);
};

const PlotOwned = () => {
  const { setSelectedSeed } = useInteractiveContext();
  const { setShowMarket } = useMarketContext();
  const { stats, crop } = useSelectedParcel();

  const cols = ['Sym', 'Chemical', 'Qty', '%'];
  if (crop) {
    cols.push('+/-');
  }

  const statTable = {
    cols: cols,
    rows: stats.map(stat => {
      const { id, volume, percentage } = stat;
      const rowContent = [<MineralBadge key={`badge-${id}`} id={id} />, id, volume, percentage];

      if (crop) {
        const cropData = findCropData(crop);
        const { changeRate } = cropData.minerals?.find((mineral) => mineral.id === id) ?? {};
        rowContent.push(<ChangeRate {...{ changeRate }} /> ?? '');
      }

      return rowContent;
    }),
  }

  const handleClickPurchaseSeed = (e) => {
    e.preventDefault();
    setShowMarket(prev => !prev);
    setSelectedSeed(null);
  }

  return (<>
    <div className={style.cropContainer}>
      {crop ? <Crop {...crop} /> : <Button onClick={(e) => handleClickPurchaseSeed(e)}>Purchase Seed</Button>}
    </div>
    <Table {...statTable} />
  </>)
};

export const Plot = () => {
  const { id, landPrice } = useSelectedParcel();

  return (<Container>

    <Title>
      <div className={styleLand.spaceBetween}>
        <div>Plot</div>
        <div className={styleLand.dim}>#{id}</div>
      </div>
    </Title>


    {landPrice ? <PlotForSale /> : <PlotOwned />}

  </Container>);
};