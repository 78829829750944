import {
  FORBID_CELLS
} from './constants';

import style from "./Rarity.module.css";

// NAVIGATION
export const isForbidden = (nextPosition, cellArray) => FORBID_CELLS.includes(cellArray[nextPosition]);

export const goRight = position => (position + 1);
export const goLeft = position => (position - 1);
export const goUp = (position, columnCount) => (position - columnCount);
export const goDown = (position, columnCount) => (position + columnCount);

export const findEdge = (position, columnCount, totalCells) => {
  const clonedPosition = parseInt(position, 10);
  const adjustedPosition = clonedPosition + 1; // to offset for zero index
  const rightEdge = adjustedPosition % columnCount === 0;
  const leftEdge = clonedPosition % columnCount === 0;
  const topEdge = position < columnCount;
  const bottomEdge = position > (totalCells - columnCount);
  return { rightEdge, leftEdge, bottomEdge, topEdge }
};

export const edgesToClass = (edges) => {
  if (edges.rightEdge) {
    return style.rightEdge;
  }
  if (edges.leftEdge) {
    return style.leftEdge;
  }
  if (edges.topEdge) {
    return style.topEdge;
  }
  if (edges.bottomEdge) {
    return style.bottomEdge;
  }
  else {
    return;
  }
};

export const oppositeEdge = (edges, position, columnCount, rowCount) => {
  if (edges.rightEdge) {
    return position - (columnCount - 1);
  }
  if (edges.leftEdge) {
    return position + (columnCount - 1);
  }
  if (edges.topEdge) {
    return position + ((rowCount - 1) * columnCount);
  }
  if (edges.bottomEdge) {
    return position - ((rowCount - 1) * columnCount);
  }
  else {
    return;
  }
};