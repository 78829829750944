import './Cells.css';
import './Targets.css';
import { calcClickable, clearClickable, setClickable } from '../actions/CellActions';
import { addIt, calcBoardSize, clearIt, findActive, findNext, isComplete, moveIt, toggleTargetActive, whatToDo } from '../actions/NewActions';
import { useTimerContext } from "../actions/useGameTimer";
import { useBoardContext } from '../context/BoardContext';
import { useTargetContext } from '../context/TargetContext';

const Cell = ({ cell }) => {
  const { id, value, clickable, target } = cell
  const { setBoardData, boardData } = useBoardContext();
  const { rowLength } = calcBoardSize({ boardData });
  const { targetData, setTargetData } = useTargetContext();
  const { startTimer, stopTimer } = useTimerContext();

  const handleClick = () => {

    const activeTarget = findActive(targetData);

    const subjectTarget = activeTarget ?? findNext(cell, targetData);

    const action = whatToDo(cell, subjectTarget);
    const arrayOfClickable = calcClickable(id, rowLength);

    switch (action) {
      case 'OVERWRITE':
        clearIt(targetData[cell.target], setTargetData, setBoardData);
        addIt(cell, subjectTarget, setTargetData, setBoardData);
        setClickable(arrayOfClickable, setBoardData);
        break;
      case 'CLEAR':
        clearIt(activeTarget, setTargetData, setBoardData);
        clearClickable(setBoardData);
        break;
      case 'ADD':
        addIt(cell, subjectTarget, setTargetData, setBoardData);
        setClickable(arrayOfClickable, setBoardData);
        startTimer();
        break;
      case 'LOCK':
        addIt(cell, activeTarget, setTargetData, setBoardData);
        clearClickable(setBoardData);
        toggleTargetActive(activeTarget, setTargetData);
        if (isComplete(targetData)) {
          stopTimer();
        }
        break;
      case 'MOVE':
        addIt(cell, activeTarget, setTargetData, setBoardData);
        const nextTarget = findNext(cell, targetData);
        if (!nextTarget) {
          clearIt(activeTarget, setTargetData, setBoardData);
          clearClickable(setBoardData);
          break;
        }
        moveIt(activeTarget, nextTarget, setTargetData, setBoardData);
        setClickable(arrayOfClickable, setBoardData);
        break;
      default:
        break;
    }
  };

  return (<button
    className={`cell ${clickable ? 'clickable' : ''} ${parseInt(target, 10) >= 0 ? 'pressed target' + target : ''}`}
    aria-pressed={clickable}
    {...{ onClick: clickable ? () => handleClick() : null }}
  >
    <span className="cellValue">{value}</span>
  </button>)
}

export const Cells = () => {
  const { boardData } = useBoardContext();
  const boardList = Object.values(boardData);
  const { rowLength } = calcBoardSize({ boardData });

  return (<div className={`board board${rowLength}`}>
    {boardList.map(cell => (<Cell key={cell.id} cell={cell} />))}
  </div>);
};