import { Temporal } from "@js-temporal/polyfill";
import React, { useContext, useEffect, useState } from "react";
import { generateRarityArray } from "../../rarity-test/calculations";
import { useSaveLoadContext } from "../../save-load/ModalContext";
import { findCropData } from "../components/Crop";
import { CHEMICALS, TOTAL_PARCELS } from "../constants";
import { getRandomInt } from "../utils/generateInt";

const calcMineralPercentage = (mineralsWithVol) => {
  const totalVolume = mineralsWithVol.reduce((sum, mineral) => sum + mineral.volume, 0);
  const newStats = mineralsWithVol.map(mineral => ({
    ...mineral,
    percentage: Math.round((mineral.volume / totalVolume) * 100),
  }));
  return newStats;
}

export const mineralRarity = () => {
  const mineralArray = Object.values(CHEMICALS);
  const rarities = { 1: [], 2: [], 3: [], 4: [] };
  mineralArray.forEach(mineral => {
    rarities[mineral.RARITY].push(mineral);
  });
  return rarities;
}

const generateParcelMinerals = () => {
  const sampleMinerals = generateRarityArray(5, mineralRarity(), true);
  // const sampleMinerals = getRandomArray(mineralArray, 5); // do not consider rarity
  const mineralsWithVol = sampleMinerals.map(({ id }) => ({ id, volume: getRandomInt(100, 999) }));

  const newStats = calcMineralPercentage(mineralsWithVol);

  newStats.sort((a, b) => b.volume - a.volume);
  return newStats;
}

const generateParcels = () => {
  const totalParcelCount = TOTAL_PARCELS;

  const allParcels = [];
  for (let i = 0; i <= totalParcelCount; i++) {
    if (i > 4) {
      allParcels.push({
        id: i,
        landPrice: getRandomInt(500, 10000),
      });
    } else {
      allParcels.push({
        id: i,
        stats: generateParcelMinerals(),
        crop: null,
        landPrice: null,
      });
    }
  }
  return allParcels;
}


const defaultContext = {
  gameData: {
    cash: 10000,
    date: Temporal.PlainDate.from('1900-01-01'),
    parcels: generateParcels(),
  },
  sowCrop: () => { },
  applyGameData: () => { },
  purchaseParcel: () => { },
}

export const PlayerContext = React.createContext(defaultContext);

export const usePlayerContext = () => {
  const gameContext = useContext(PlayerContext);
  return gameContext;
}

export const PlayerContextProvider = ({ children }) => {
  const { loadedData } = useSaveLoadContext();
  const [pauseClock, setPauseClock] = useState(false);
  const [gameData, setGameData] = useState(null);

  const tickMinerals = () => {
    const clonedData = { ...gameData };
    const newParcels = clonedData.parcels.map(parcel => {
      const { crop } = parcel;

      if (!crop) {
        return parcel;
      } else {
        const cropData = findCropData(crop);
        const cropMinerals = cropData.minerals.map(({ id }) => id);
        const parcelMinerals = Object.values(parcel.stats);

        // Calc new Minerals
        let successfulMineralCount = 0;
        const mineralsWithVol = parcelMinerals.map(parcelMineral => {
          if (cropMinerals.includes(parcelMineral.id)) {
            const cropMineral = cropData.minerals.find(cropMineral => cropMineral.id === parcelMineral.id);
            const newVolume = parcelMineral.volume > 0 ? parcelMineral.volume + cropMineral.changeRate : 0;
            parcelMineral.volume = newVolume;
            if (newVolume > 0) {
              successfulMineralCount++;
            }
          }
          return parcelMineral;
        });

        const newStats = calcMineralPercentage(mineralsWithVol);

        // Calc days
        const daysRemaining = crop.daysRemaining - 1;

        // Calc health
        const healthRate = 100 / cropData.ready / 4; // % of health that can added per successful mineral
        const healthValue = daysRemaining > 0
          ? crop.health + (healthRate * successfulMineralCount) // if crop is still growing, ADD to health
          : crop.health - ((healthRate * successfulMineralCount) * 2); // if crop is overdue, SUBTRACT to health

        const health = parseFloat(healthValue.toFixed(1));

        //Calc Est Sale Price
        const estSalePrice = daysRemaining <= 15
          ? (cropData.harvestPrice * (healthValue / 100)).toFixed(2)
          : 1;

        return {
          ...parcel,
          crop: { ...crop, daysRemaining, estSalePrice, health },
          stats: newStats,
        };
      }
    });


    setGameData(prev => ({ ...prev, parcels: newParcels }));
  };

  const tickClock = () => {
    const clonedData = { ...gameData };
    setGameData(prev => ({ ...prev, date: clonedData.date.add({ days: 1 }) }));
  };

  // ON LOAD
  useEffect(() => {
    if (loadedData) {
      setGameData(loadedData);
    }
  }, [loadedData])

  // CLOCK
  useEffect(() => {
    if (!gameData) return;
    if (pauseClock) return;

    const timer = setTimeout(() => {
      tickClock();
      tickMinerals();
    }, 2000); // should be 10000
    return () => clearTimeout(timer);
  });

  const sowCrop = ({ cropId, parcelId }) => {
    const { parcels } = gameData;
    const clonedParcels = [...parcels];
    const { seedPrice, ready } = findCropData({ id: cropId });
    const parcelIndex = parcels.findIndex(({ id }) => id === parcelId);
    const freshParcel = {
      ...parcels[parcelIndex],
      crop: {
        id: cropId,
        daysRemaining: ready,
        health: 0,
        estSalePrice: 1,
      },
    };
    clonedParcels.splice(parcelIndex, 1, freshParcel)
    setGameData(prev => ({
      ...prev,
      cash: prev.cash - seedPrice,
      parcels: [...clonedParcels],
    }));
  }

  const purchaseParcel = ({ parcelId }) => {
    const { parcels } = gameData;
    const parcelIndex = parcels.findIndex(({ id }) => id === parcelId);
    const selectedParcel = parcels[parcelIndex];
    const prevLandPrice = selectedParcel.landPrice;
    const freshParcel = {
      ...selectedParcel,
      landPrice: null,
    };
    const clonedParcels = [...parcels];
    clonedParcels.splice(parcelIndex, 1, freshParcel)
    setGameData(prev => ({
      ...prev,
      cash: prev.cash - prevLandPrice,
      parcels: [...clonedParcels],
    }));
  }

  const applyGameData = ({ loadedData }) => {
    setGameData(loadedData);
  }

  const newGame = () => {
    setGameData(defaultContext.gameData)
  }

  const togglePause = () => {
    setPauseClock(prev => !prev);
  }

  return (
    <PlayerContext.Provider value={{
      newGame,
      gameData,
      applyGameData,
      sowCrop,
      purchaseParcel,
      pauseClock,
      togglePause,
    }}>
      {children}
    </PlayerContext.Provider>
  );
}