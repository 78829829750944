import { createContext, useCallback, useContext, useEffect, useState } from "react";

export const TimerContext = createContext({
  time: 0,
  startTimer: () => { },
  stopTimer: () => { },
  resetTimer: () => { },
});

export const useTimerContext = () => useContext(TimerContext);


const TimerContextProvider = ({ children }) => {
  const [time, setTime] = useState(0);
  const [runTimer, setRunTimer] = useState(false);

  useEffect(() => {
    if (!runTimer) return;
    const timer = setInterval(() => {
      setTime(prev => prev + 1);
    }, 1000);

    return () => clearInterval(timer);

  }, [runTimer]);

  const startTimer = () => {
    if (time === 0) {
      setRunTimer(true);
    }
  };
  const stopTimer = () => {
    setRunTimer(false);
  };
  const resetTimer = useCallback(() => {
    setRunTimer(false);
    setTime(0);
  }, [setRunTimer, setTime]);

  return <TimerContext.Provider value={{
    time,
    startTimer, stopTimer,
    resetTimer,
  }}>{children}</TimerContext.Provider>
}
export default TimerContextProvider;