import { useEffect, useRef, useState } from "react";
import style from "./Rarity.module.css";
import {
  RARITY_MATRIX,
  idToSym,
  LEVELS
} from './constants';
import { generateRarityArray } from './calculations.js'
import {
  oppositeEdge,
  findEdge,
  isForbidden,
  goRight,
  goLeft,
  goUp,
  goDown,
  edgesToClass,
} from './navigation.js'

const findColCount = () => {
  const windowWidth = window.innerWidth;
  const slicesBy20 = Math.floor(windowWidth / 20);
  const evenNumber = slicesBy20 % 2 === 0 ? slicesBy20 : slicesBy20 - 1;
  const columnCount = evenNumber < 60 ? evenNumber : 60;
  return { columnCount, windowWidth };
}

const findRowCount = () => {
  const windowHeight = window.innerHeight / 2;
  const slicesBy20 = Math.floor(windowHeight / 20);
  const evenNumber = slicesBy20 % 2 === 0 ? slicesBy20 : slicesBy20 - 1;
  const rowCount = evenNumber < 60 ? evenNumber : 60;
  return { rowCount, windowHeight };
}

// GAME BOARD
const useFeildSize = () => {
  const { columnCount, windowWidth } = findColCount();
  const { rowCount } = findRowCount();

  console.log('columnCount', columnCount);
  console.log('rowCount', rowCount);

  const totalCells = columnCount * rowCount;

  console.log('totalCells', totalCells);

  const startingPosition = (rowCount / 2) + (columnCount / 2);
  return {
    windowWidth,
    columnCount,
    rowCount,
    totalCells,
    startingPosition,
  }
}

const generateInventory = (level) => {
  const levelObj = LEVELS[level];
  const inventory = {};
  Object.keys(levelObj).forEach(key => inventory[key] = 0);
  return inventory;
}

// DISPLAY COMPONENTS
export const Rarity = () => {
  const [level, setLevel] = useState(1);
  const [, setLastKey] = useState(null);
  const { columnCount, rowCount, totalCells, startingPosition } = useFeildSize();
  const cells = generateRarityArray(totalCells, RARITY_MATRIX, false);
  const [cellArray, setCellArray] = useState(cells);
  const [inventory, setInventory] = useState(generateInventory(2));
  const [position, setPosition] = useState(startingPosition);

  const field = useRef(null);

  const refreshField = () => {
    const newCells = generateRarityArray(totalCells, RARITY_MATRIX, false);
    setCellArray(newCells);
  }

  useEffect(() => {
    field.current.focus();
  }, []);

  useEffect(() => {
    const clearedLevel = Object.keys(LEVELS[level]).every(key => inventory[key] >= LEVELS[level][key]);
    if (clearedLevel) {
      alert('Cleared Level ', level);
      const newLevel = level + 1;
      setLevel(newLevel);
      const newInventory = generateInventory(newLevel);
      setInventory(newInventory)
    }
  }, [inventory, level]);


  const overEdge = (position) => {
    refreshField();
    const oppositeCell = oppositeEdge(edge, position, columnCount, rowCount);
    setPosition(oppositeCell);
  }

  const edge = findEdge(position, columnCount, cells.length);

  const handleRight = () => {
    if (edge.rightEdge) {
      overEdge(position);
      return;
    }
    if (isForbidden(goRight(position), cellArray)) return;

    setPosition(prev => goRight(prev));
  };
  const handleLeft = () => {
    if (edge.leftEdge) {
      overEdge(position);
      return;
    }
    if (isForbidden(goLeft(position), cellArray)) return;

    setPosition(prev => goLeft(prev));
  };
  const handleUp = () => {
    if (edge.topEdge) {
      overEdge(position);
      return;
    }

    if (isForbidden(goUp(position, columnCount), cellArray)) return;

    setPosition(prev => goUp(prev, columnCount));
  };
  const handleDown = () => {
    if (edge.bottomEdge) {
      overEdge(position);
      return;
    }
    if (isForbidden(goDown(position, columnCount), cellArray)) return;

    setPosition(prev => goDown(prev, columnCount));
  };
  const handlePickUp = () => {
    const item = cellArray[position];
    if (Object.keys(LEVELS[level]).includes(item)) {
      setInventory(prev => ({ ...prev, [item]: parseInt(prev[item], 10) + 1 }));
      const clonedCells = [...cellArray];
      clonedCells.splice(position, 1, 'ground');
      setCellArray([...clonedCells]);
    }
  };

  const handleKeyDown = (event) => {
    event.preventDefault();
    setLastKey(event.keyCode);

    if (event.keyCode === 39) { // RIGHT
      handleRight();
    }
    if (event.keyCode === 38) { // UP
      handleUp();
    }
    if (event.keyCode === 37) { // LEFT
      handleLeft();
    }
    if (event.keyCode === 40) { // DOWN
      handleDown();
    }
    if (event.keyCode === 32) { // SPACE
      handlePickUp();
    } else {
      return;
    }
  };

  return (<div className={style.page}>
    <div className={style.pageWrapper}>
      <div className={style.gameHeader}>
        <div>
          <h1>Rarity Woods</h1>
        </div>
        <div>
          <small>v0.0.1</small>
        </div>
      </div>

      <section className={style.pageWrapper}>

        <div ref={field} onKeyDown={(e) => handleKeyDown(e)} tabIndex="0" className={`${style.playField} ${edgesToClass(edge)}`} style={{ width: columnCount * 20 }}>
          {cellArray.map((value, index) =>
            <Tile {...{ value, index, active: position === index }} />)}
        </div>

        <Hud {...{ position, inventory, level, columnCount, rowCount }} />


        <div className={style.controls}>
          <div className={style.dPad}>
            <div />
            <button onClick={() => handleUp()}>🔼</button>
            <div />
            <button onClick={() => handleLeft()} aria-label="go left">◀️</button>
            <div />
            <button onClick={() => handleRight()} aria-label="go right">▶️</button>
            <div />
            <button onClick={() => handleDown()}>🔽</button>
            <div />
          </div>
          <div>
            <button className={style.pickUpBtn} onClick={() => handlePickUp()}>PICK UP</button>
          </div>
        </div>

      </section>
    </div>
  </div>);
}

const Hud = ({ position, inventory, level, columnCount, rowCount }) => {
  useEffect(() => { }, [inventory]);
  return (<div className={style.hud} style={{ width: (columnCount * 20) - 20 }}>

    <div className={style.statsBody}>
      <div>Cell {JSON.stringify(position)}</div>
      <div>{JSON.stringify(columnCount)} x {JSON.stringify(rowCount)}</div>
    </div>

    {/* INVENTORY */}
    <h4>Level: {JSON.stringify(level)}</h4>
    <div className={style.inventory}>{Object.keys(LEVELS[level]).map((item, index) =>
      <div className={style.inventoryItem}>
        <Tile {...{ value: item, index }} />: {inventory[item]} / {LEVELS[level][item]} </div>
    )}</div>
  </div>)
}

const Tile = ({ value, index, active = false }) =>
  <div key={`cell-${index}`} id={`cell-${index}`} className={`${style.cell} ${style[value]} ${active ? style.standing : ""}`}>
    {idToSym[value] ?? value[0].toUpperCase()}
    {/* <small>{index}</small> */}
  </div>
