import { generateBoardAndTarget, resetBoard, resetTargets } from '../actions/GenerateNumberLevels';
import { saveLevel } from '../actions/SaveLevel';
import { useBoardContext } from '../context/BoardContext';
import { useTargetContext } from '../context/TargetContext';
import '../Numbers.css';
import './DevControls.css';

export const DevControls = () => {
  const { targetData, setTargetData } = useTargetContext();
  const { boardData, setBoardData } = useBoardContext();

  const handleClick = (size) => {
    const { board, targets } = generateBoardAndTarget(size);
    setTargetData(targets);

    Object.values(targets).forEach(({ id, cells }) => {
      cells.forEach(cell => {
        board[cell].target = id;
      });
    });

    setBoardData(board);
  }


  const handleSaveLevel = () => {
    const clearedBoardData = resetBoard(boardData, setBoardData);
    const clearedTargetData = resetTargets(targetData);
    saveLevel({ data: { boardData: clearedBoardData, targetData: clearedTargetData } })
  }

  return (<div className='devControls'>
    <p>Dev Controls</p>
    <button onClick={() => handleClick(3)}>Generate 3x3</button>
    <br />
    <button onClick={() => handleClick(4)}>Generate 4x4</button>
    <br />
    <button onClick={() => handleClick(5)}>Generate 5x5</button>
    <br />
    <button onClick={() => handleSaveLevel()}>Save Level</button>
  </div>);
}