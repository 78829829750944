export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

export const getLetters = (matrix) => matrix.map(subArray => subArray[0]);

export const countInstances = (finalArray, matrix) => {
  const letters = getLetters(matrix);
  const letterCounts = {};
  letters.forEach(letter => letterCounts[letter] = finalArray.reduce((acc, curr) => curr === letter ? acc + 1 : acc, 0));
  return letterCounts;
}

export const createCompleteArray = (rarityScores) => {
  const totalArray = [];
  Object.keys(rarityScores).forEach(count => {
    for (let i = 0; i < count; i++) {
      totalArray.push(...rarityScores[count]);
    }
  });
  return totalArray.sort();
}

// rarityScores example { 0: [A, B] } the lower the number, the more rare
export const generateRarityArray = (count, rarityScores, exclusive = false) => {
  const referenceArray = createCompleteArray(rarityScores);
  const finalArray = [];
  const possibleValues = Object.values(rarityScores).flat();
  const runExclusive = exclusive && (count < possibleValues.length);
  if (runExclusive) {
    do {
      const randomInt = getRandomInt(0, referenceArray.length - 1);
      const selected = referenceArray[randomInt];
      if (!finalArray.includes(selected)) {
        finalArray.push(selected);
      }
    } while (finalArray.length < count);
  } else {
    do {
      const randomInt = getRandomInt(0, referenceArray.length - 1);
      const selected = referenceArray[randomInt];
      finalArray.push(selected);
    } while (finalArray.length < count);
  }

  return finalArray;
};


