import { useState } from 'react';
import styles from './Clue.module.css';

const checkAnswer = ({ userInput = '', answer = '' }) => {
  const parsedInput = userInput.toLowerCase();
  const parsedAnswer = answer.toLowerCase();

  return parsedInput === parsedAnswer;
}

const AnswerInput = ({ handleSubmit, isInvalid, setInvalid, active }) => {
  const [userInput, setUserInput] = useState(null);

  const disabledForm = !active;

  const clickSubmit = (e) => {
    e.preventDefault();
    handleSubmit(userInput);
  }

  const handleChange = (e) => {
    setUserInput(e.currentTarget.value)
    setInvalid(false);
  }

  return (<form onSubmit={(e) => clickSubmit(e)}>
    <div className={styles.form}>
      <input
        type='text'
        className={`${isInvalid ? styles.incorrect : null}`}
        aria-invalid={isInvalid}
        value={userInput}
        onChange={(e) => handleChange(e)}
        disabled={disabledForm}
      />
      <br />
      {disabledForm ? null : <button disabled={disabledForm} className={styles.submitButton} type='submit'>Submit</button>}
    </div>
  </form>);
}

export const Clue = ({ title, question, answer, active, advanceLevel, id }) => {
  const [correct, setCorrect] = useState(false);
  const [isInvalid, setInvalid] = useState(false);
  const handleSubmit = (userInput) => {
    const isCorrect = checkAnswer({ userInput, answer });
    setCorrect(isCorrect);
    if (isCorrect) {
      advanceLevel();
    } else {
      setInvalid(true);
    }
  };
  if (!correct && !active) {
    return <div className={`${styles.clueContainer} ${styles.locked}`}>
      <p className={styles.clueQuestion}>Locked</p>
    </div>
  }
  return (<div className={`${styles.clueContainer} 
  ${active ? styles.active : styles.locked}
  ${correct ? styles.correct : null}`}>
    <div className={styles.questionRow}>
      <p className={styles.clueQuestion}>{question}</p>
      <p className={styles.clueId}>#{id}</p>
    </div>
    <div>
      <AnswerInput {...{ handleSubmit, isInvalid, setInvalid, active }} />
      <p>Correct: {JSON.stringify(correct)}</p>
    </div>
  </div>)
}